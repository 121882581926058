import $http from '@axios'

export default {
  namespaced: true,
  state: {
    formPayloadOthers: {
      date: '',
      jenis_pembayaran: '',
      tipe_pembayaran: '',
      detail: '',
      receiver: '',
      invoice_number_spt: '',
      amount: 0,
      payment_method: '',
      file_attachment: '',
    },
    payment_method: '',
  },
  getters: {},
  mutations: {
    setFormPayloadOthers(state, value) {
      state.formPayloadOthers = value
    },
    setPaymentMethod(state, value) {
      state.payment_method = value
    },
  },
  actions: {
    getSupplier(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`/payment/supplier/${uuid}`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getOthers(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`/payment/other/${uuid}`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    cancelOthers(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`/payment/other/${uuid}`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    cancelSupplier(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`/payment/supplier/${uuid}`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getDetailOrder(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`/payment/sales/${uuid}`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    postOthers(context, { payload, uuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`/payment/other${uuid || ''}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
