import $http from '@axios'

export default {
  namespaced: true,
  state: {
    userData: {},
    totalBuy: 0,
    is_selected_customer: false,
    po_number: '',
    discount: '0',
    discounts: {
      type: '',
      discount: 0,
    },
    indexDiscounts: false,
    isAddDiscount: false,
    isEdit: false,
  },
  getters: {},
  mutations: {
    SET_IS_EDIT(state, isEdit) {
      state.isEdit = isEdit
    },
    setUserData(state, value) {
      state.userData = value
    },
    setSelectedCustomer(state, value) {
      state.is_selected_customer = value
    },
    setDiscount(state, value) {
      state.discount = value
    },
    setIndexDiscounts(state, value) {
      state.indexDiscounts = value
    },
    setIsAddDiscount(state, value) {
      state.isAddDiscount = value
    },
    setDiscounts(state, value) {
      state.discounts = value
    },
    setProduct(state, value) {
      state.totalBuy = value
    },
  },
  actions: {
    getData(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`/cashier/product${uuid}`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    toggleFavorite(context, { uuid, type }) {
      return new Promise((resolve, reject) => {
        $http.post(`/cashier/product/${uuid || ''}/${type}`)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
