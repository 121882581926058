import $http from '@axios'

export default {
  namespaced: true,
  state: {
    formPayload: {
      supplier_uuid: '',
      voucher_code: '',
      amount: '',
      start_date: '',
      expire_date: '',
    },
    uuid: false,
  },
  getters: {},
  mutations: {
    setFormPayload(state, value) {
      state.formPayload = value
    },
    setUuid(state, value) {
      state.uuid = value
    },
  },
  actions: {
    getData(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`/vouchers/${uuid || ''}`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    postData(context, { payload, uuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`/vouchers${uuid || ''}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    deleteData(context, { uuid }) {
      return new Promise((resolve, reject) => {
        $http.delete(`/vouchers${uuid || ''}`)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
