import $http from '@axios'

export default {
  namespaced: true,
  state: {
    merchant: null,
    profile: null,
    jabatan: '',
  },
  getters: {},
  mutations: {
    SET_JABATAN(state, jabatan) {
      state.jabatan = jabatan
    },
    setProfile(state, value) {
      state.profile = value
    },
    setMerchant(state, value) {
      state.merchant = value
    },
  },
  actions: {
    getProfile() {
      return new Promise((resolve, reject) => {
        $http.get('/me')
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getMerchant() {
      return new Promise((resolve, reject) => {
        $http.get('/merchant')
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    postMerchant(context, payload) {
      return new Promise((resolve, reject) => {
        $http.post('/merchant', payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
