import Vue from 'vue';
import moment from 'moment';

Vue.filter('formatDateRegister', (value) => {
  if (value) {
    return moment(String(value)).format('hh:mm DD MMMM YYYY');
  }
});

Vue.filter('formatDate', (value) => {
  if (value) {
    return moment(String(value)).format('DD MMMM YYYY');
  }
});

Vue.filter('formatDateWithTime', (value) => {
  if (value) {
    return moment(String(value)).format('DD MMMM YYYY, hh:mm:ss');
  }
});
