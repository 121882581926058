import $http from '@axios'

export default {
  namespaced: true,
  state: {
    editId: null,
    npwp_url: '',
    ktp_url: '',
    formPayload: {
      customer_type: 'Toko',
      email: '',
      name: '',
      alias_name: '',
      phone_number: null,
      contact_name: '',
      address: '',
      terms_of_payment: '',
      terms_of_payment_day: 0,
      tax_id_photo: '',
      tax_id_number: '',
      id_card_photo: '',
      id_card_number: '',
    },
    result: {},
    customer: {},
    detailPiutang: {},
    isUtang: false,
    formPayloadPiutang: {},
    formPayloadTopup: {},
    updateData: false,
  },
  getters: {},
  mutations: {
    setUpdateData(state, value) {
      state.updateData = value
    },
    setFormPayloadTopup(state, value) {
      state.formPayloadTopup = value
    },
    setFormPayloadPiutang(state, value) {
      state.formPayloadPiutang = value
    },
    setIsUtang(state, value) {
      state.isUtang = value
    },
    setDetailPiutang(state, value) {
      state.detailPiutang = value
    },
    setCustomer(state, value) {
      state.customer = value
    },
    setCustomers(state, value) {
      state.customer = value
    },
    setNpwpUrl(state, value) {
      state.npwp_url = value
    },
    setKtpUrl(state, value) {
      state.ktp_url = value
    },
    setEditId(state, value) {
      state.editId = value
    },
    setFormPayload(state, value) {
      state.formPayload = value
    },
    setResult(state, value) {
      state.result = value
    },
  },
  actions: {
    getData(context, { params }) {
      return new Promise((resolve, reject) => {
        $http.get('/customer', { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getDetail(context, { uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`/customer/${uuid || ''}`)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getDetailPiutang(context, { uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`/piutang/${uuid || ''}`)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getHistory(context, { id, params }) {
      return new Promise((resolve, reject) => {
        $http.get(`/customer/${id}/history`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    postData(context, { payload, query }) {
      return new Promise((resolve, reject) => {
        $http.post(`/customer${query || ''}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    postPiutang(context, { payload, customerUuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`/piutang/${customerUuid || ''}/add-payment`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    deleteData(context, query) {
      return new Promise((resolve, reject) => {
        $http.delete(`/customer${query || ''}`)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    clearItem(context) {
      context.commit('setNpwpUrl', null)
      context.commit('setKtpUrl', null)
      context.commit('setEditId', null)
      context.commit('setFormPayload', {
        customer_type: '',
        email: '',
        name: '',
        alias_name: '',
        phone_number: '',
        contact_name: '',
        address: '',
        terms_of_payment: '',
        tax_id_number: '',
        id_card_number: '',
      })
    },
  },
}
