/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import Vue from 'vue';
// import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useJwt from '@/auth/jwt/useJwt';
// axios
import axios from 'axios';

const axiosIns = axios.create({
  baseURL: process.env.API_BASE_URL,
  headers: {
    Accept: 'application/json',
    // 'Cache-Control': 'no-cache',
  },
  // adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter)),
});

Vue.prototype.$http = axiosIns;

axiosIns.interceptors.request.use(
  (request) => {
    const accessToken = localStorage.getItem('accessToken');
    // If token is present add it to request's Authorization Header
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      request.headers.Authorization = `Bearer ${accessToken}`;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

axiosIns.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // Vue.$toast({
      //   component: ToastificationContent,
      //   position: 'top-right',
      //   props: {
      //     title: 'Login Gagal!',
      //     icon: 'DangerIcon',
      //     variant: 'danger',
      //     text: (error.response.data && error.response.data.meta && error.response.data.meta.messages.join(', ')) || '',
      //   },
      // })
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userData');
      localStorage.removeItem('permissions');
      // if (window.location.pathname !== '/login') {
      //   window.location.href = `${window.location.origin}/login`
      // }
      const up = JSON.parse(localStorage.getItem('up'));
      if (up) {
        const form = new FormData();
        if (up.isE) {
          form.append('email', up.u);
        } else {
          const formattedPhone = formatPhone(up.u, up.s);
          form.append('phone', formattedPhone);
        }

        form.append('password', up.p);

        useJwt.login(form).then((response) => {
          const data = response.data.data;
          if (data) {
            useJwt.setToken(data.token);
            localStorage.setItem(
              'userPermission',
              JSON.stringify(data.permission)
            );
            localStorage.setItem('userRole', JSON.stringify(data.roles));
            localStorage.setItem('userData', JSON.stringify(data.user));
            localStorage.removeItem('up');
          }
        });
      } else {
        window.location.href = `${window.location.origin}/login`;
      }
    }

    if (error.response.status === 400) {
      const { messages } = error.response.data.meta;
      if (messages.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < messages.length; index++) {
          const element = messages[index];
          Vue.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Oops!',
              icon: 'DangerIcon',
              variant: 'danger',
              text: `${element}`,
            },
          });
        }
      } else {
        for (const key in messages) {
          if (Object.hasOwnProperty.call(messages, key)) {
            const element = messages[key];
            Vue.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Oops!',
                icon: 'DangerIcon',
                variant: 'danger',
                text: `${element.join(', ')}`,
              },
            });
          }
        }
      }
    }

    if (error.response.status === 403 && !localStorage.getItem('otp_type')) {
      // window.location.href = `${window.location.origin}/error-403`
    }

    return Promise.reject(error);
  }
);
function formatPhone(emailOrPhone, selectedCountry) {
  let phone = emailOrPhone;
  if (phone.startsWith('0')) {
    phone = phone.substring(1);
  }
  phone = `${selectedCountry.replace(/^\+/, '')}${phone}`;
  return phone;
}
export default axiosIns;
