function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`);
}

export default [
  {
    path: "/login",
    name: "login",
    component: lazyLoad("Login"),
    meta: {
      guest: true,
      layout: "full",
    },
  },
  // {
  //   path: '/login-supplier',
  //   name: 'login-supplier',
  //   component: lazyLoad('Login-supplier'),
  //   meta: {
  //     guest: true,
  //     layout: 'full',
  //   },
  // },
  {
    path: "/daftar-new",
    name: "register",
    component: lazyLoad("register"),
    meta: {
      guest: true,
      layout: "full",
    },
  },
  {
    path: "/lupa-password",
    name: "forgot-password",
    component: lazyLoad("forgot-password"),
    meta: {
      guest: true,
      layout: "full",
    },
  },
  {
    path: "/ubah-password",
    name: "change-password",
    component: lazyLoad("change-password"),
    meta: {
      guest: true,
      layout: "full",
    },
  },
  {
    path: "/otp",
    name: "otp",
    component: lazyLoad("otp"),
    meta: {
      auth: false,
      layout: "full",
    },
  },
  {
    path: "/cashier",
    name: "cashier.index",
    component: lazyLoad("cashier/index"),
    meta: {
      auth: true,
      layout: "full",
    },
  },
  {
    path: "/kas-kecil",
    name: "cash.index",
    component: lazyLoad("cash/index"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/",
    name: "welcome.index",
    component: lazyLoad("welcome"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/transaksi",
    name: "transaction.index",
    component: lazyLoad("transaction/index"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/transaks/:id",
    name: "transaction.show",
    component: lazyLoad("transaction/detail/show"),
    meta: {
      auth: true,
      layout: "full",
      navActiveLink: "transaction.index",
    },
  },
  {
    path: "/keranjang",
    name: "cart.index",
    component: lazyLoad("cart/index"),
    meta: {
      auth: true,
      layout: "full",
    },
  },
  {
    path: "/stok",
    name: "stock.index",
    component: lazyLoad("stock/index"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/stok/produk-masuk",
    name: "stock.add.index",
    component: lazyLoad("stock/add/index"),
    meta: {
      auth: true,
      layout: "full",
    },
  },
  {
    path: "/stok/gudang",
    name: "stock.gudang",
    component: lazyLoad("stock/gudang"),
    meta: {
      auth: true,
      navActiveLink: "stock.index",
    },
  },
  {
    path: "/stok/supplier",
    name: "stock.supplier",
    component: lazyLoad("stock/supplier"),
    meta: {
      auth: true,
      navActiveLink: "stock.index",
    },
  },
  {
    path: "/stok/transfer",
    name: "stock.transfer",
    component: lazyLoad("stock/transfer"),
    meta: {
      auth: true,
      navActiveLink: "stock.index",
    },
  },
  {
    path: "/stok/transfer/create",
    name: "stock.transfer_create",
    component: lazyLoad("stock/detail/transfer_create"),
    meta: {
      auth: true,
      navActiveLink: "stock.index",
      layout: "full",
    },
  },
  {
    path: "/stok/transfer/:id",
    name: "stock.transfer_detail",
    component: lazyLoad("stock/detail/transfer_detail"),
    meta: {
      auth: true,
      navActiveLink: "stock.index",
      layout: "full",
    },
  },
  {
    path: "/stok/opname",
    name: "stock.opname",
    component: lazyLoad("stock/opname"),
    meta: {
      auth: true,
      navActiveLink: "stock.index",
    },
  },
  {
    path: "/pembayaran",
    name: "payment.index",
    component: lazyLoad("payment/index"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pembayaran/pilih-metode-pembayaran",
    name: "payment.select_payment",
    component: lazyLoad("payment/select_payment"),
    meta: {
      layout: "full",
    },
  },
  // {
  //   path: '/pembayaran/lengkapi-data',
  //   name: 'payment.completeData',
  //   component: () => import('@/views/payment/payment_dp_split.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  {
    path: "/pembayaran/split-payment",
    name: "payment.split",
    component: () => import("@/views/payment/payment_dp_split.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pembayaran/tunai",
    name: "payment.tunai",
    component: lazyLoad("payment/payment_method/tunai"),
    meta: {
      auth: true,
      layout: "full",
    },
  },
  {
    path: "/pembayaran/kredit",
    name: "payment.kredit",
    component: lazyLoad("payment/payment_method/kredit"),
    meta: {
      auth: true,
      layout: "full",
    },
  },
  {
    path: "/pembayaran/transfer-bank",
    name: "payment.transfer",
    component: lazyLoad("payment/payment_method/transfer_bank"),
    meta: {
      auth: true,
      layout: "full",
    },
  },
  {
    path: "/pembayaran/giro",
    name: "payment.giro",
    component: lazyLoad("payment/payment_method/giro"),
    meta: {
      auth: true,
      layout: "full",
    },
  },
  {
    path: "/pembayaran/deposit",
    name: "payment.deposit",
    component: lazyLoad("payment/payment_method/deposit"),
    meta: {
      auth: true,
      layout: "full",
    },
  },
  {
    path: "/pembayaran/piutang",
    name: "payment.piutang",
    component: lazyLoad("payment/payment_method/piutang"),
    meta: {
      auth: true,
      layout: "full",
    },
  },
  {
    path: "/pembayaran/success",
    name: "payment.success",
    component: lazyLoad("payment/payment_success"),
    meta: {
      auth: true,
      layout: "full",
    },
  },
  {
    path: "/invoice/:id",
    name: "invoice.index",
    component: lazyLoad("payment/invoice"),
    meta: {
      auth: true,
      layout: "full",
    },
  },
  {
    path: "/retur",
    name: "retur.index",
    component: lazyLoad("retur/index"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/retur/:id",
    name: "retur.detail.index",
    component: lazyLoad("retur/detail/index"),
    meta: {
      auth: true,
      layout: "full",
    },
  },
  {
    path: "/relasi",
    name: "customer.index",
    component: lazyLoad("customer/index"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/piutang",
    name: "accounts_receivable.index",
    component: lazyLoad("accounts_receivable/index"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/deposit",
    name: "deposit.index",
    component: lazyLoad("deposit/index"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/utang",
    name: "debt.index",
    component: lazyLoad("debt/index"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/penyetoran",
    name: "payout.index",
    component: lazyLoad("payout/index"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/supplier-dashboard",
    name: "supplier-dashboard.index",
    component: lazyLoad("supplier-dashboard/index"),
    meta: {
      auth: true,
      navActiveLink: "login-supplier",
    },
  },
  {
    path: "/login-supplier",
    name: "login-supplier",
    component: lazyLoad("Login-supplier"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/laporan",
    name: "report.index",
    component: lazyLoad("report/index"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/stock/gudang/:id",
    name: "stock.gudang.detail",
    component: lazyLoad("stock/detail/product"),
    meta: {
      auth: true,
      layout: "full",
    },
  },
  {
    path: "/billing/:id",
    name: "billing.index",
    component: lazyLoad("billing/index"),
    meta: {
      auth: true,
      layout: "full",
    },
  },
  {
    path: "/ticket/",
    name: "ticket.index",
    component: lazyLoad("ticket/index"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/ticket/:id",
    name: "ticket.show",
    component: lazyLoad("ticket/detail"),
    meta: {
      auth: true,
      navActiveLink: "ticket.index",
    },
  },
  {
    path: "/brand",
    name: "brand.index",
    component: lazyLoad("master/brand/index"),
    meta: {
      // navActiveLink: "master-toko.index",
    },
  },
  {
    path: "/barang",
    name: "barang.index",
    component: lazyLoad("master/barang/index"),
    meta: {
      // navActiveLink: "master-toko.index",
    },
  },
  {
    path: "/kategori-1",
    name: "category1",
    component: lazyLoad("master/categories/category1"),
    meta: {
      // navActiveLink: "master-toko.index",
    },
  },
  {
     path: "/kategori-2",
    name: "category2",
    component: lazyLoad("master/categories/category2"),
    meta: {
      // navActiveLink: "master-toko.index",
    },
  },
  {
    path: "/kategori-3",
    name: "category3",
    component: lazyLoad("master/categories/category3"),
    meta: {
      // navActiveLink: "master-toko.index",
    },
  },
  {
    path: "/satuan",
    name: "satuan.index",
    component: lazyLoad("master/satuan/index"),
    meta: {
      // navActiveLink: "master-toko.index",
    },
  },
  {
    path: "/pos",
    name: "pos.index",
    component: lazyLoad("pos2/index"),
    meta: {
      layout: 'full'
      // navActiveLink: "master-toko.index",
    },
  },
  {
    path: "/bundle",
    name: "bundle.index",
    component: lazyLoad("bundle/index"),
    meta: {
      // navActiveLink: "master-toko.index",
    },
  },
  // {
  //   path: "/pos2",
  //   name: "pos2.index",
  //   component: lazyLoad("pos/index"),
  //   meta: {
  //     layout: 'full'
  //     // navActiveLink: "master-toko.index",
  //   },
  // },
];
