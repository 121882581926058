import $http from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {
    setFormPayload(state, value) {
      state.formPayload = value
    },
  },
  actions: {
    getData(context, { requests }) {
      return new Promise((resolve, reject) => {
        $http.get(`/batch?requests=${requests}`)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
