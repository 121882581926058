import $http from '@axios'

export default {
  namespaced: true,
  state: {
    payloadRetail: {
      retail_product_uuid: '',
      date: '',
      warehouse_uuid: '',
      qty_parent: 0,
    },
    formPayload: {
      name: '',
      address: '',
      city: '',
      logo: '',
    },
    formSupplier: {
      date: '',
      delivery_numbers: [],
      supplier_uuid: '',
      po_so_id: '',
      warehouse_uuid: '',
      shipping: '',
    },
    formProducts: {
      items: [],
      additional_fee: 0,
      additional_fee_note: '',
      note: '',
    },
    payment: {
      payment_type: '',
      tempo_due_date: '',
      tunai_type: '',
    },
    paymentDetail: {},
    payment_method: '',
    isLoading: false,
    isWarehouse: false,
    isEdit: null,
    totalProduct: 0,
    isDeliveryNumbers: false,
    discounts: {},
    indexDiscounts: false,
    isAddDiscount: false,
    multipleDiscount: [
      {
        discounts: [],
      },
    ],
  },
  getters: {},
  mutations: {
    SET_PAYMENT_DETAIL(state, value) {
      state.paymentDetail = value
    },
    SET_MULTIPLE_DISCOUNT(state, value) {
      state.multipleDiscount.push(value)
    },
    setIndexDiscounts(state, value) {
      state.indexDiscounts = value
    },
    setIsAddDiscount(state, value) {
      state.isAddDiscount = value
    },
    setDiscounts(state, value) {
      state.discounts = value
    },
    setTotalProduct(state, value) {
      state.totalProduct = value
    },
    setIsDeliveryNumbers(state, value) {
      state.isDeliveryNumbers = value
    },
    setIsEdit(state, value) {
      state.isEdit = value
    },
    setIsWarehouse(state, value) {
      state.isWarehouse = value
    },
    setIsLoading(state, value) {
      state.isLoading = value
    },
    setPayloadRetail(state, value) {
      state.payloadRetail = value
    },
    setFormPayload(state, value) {
      state.formPayload = value
    },
    setFormSupplier(state, value) {
      state.formSupplier = value
    },
    setFormProducts(state, value) {
      state.formProducts = value
    },
    setPayment(state, value) {
      state.payment = value
    },
    setPaymentMethod(state, value) {
      state.payment_method = value
    },
  },
  actions: {
    // Stock Warehouse
    getData(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`stocks/warehouse/incoming/${uuid || ''}`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    postData(context, { payload, uuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`stocks/warehouse/incoming/${uuid || ''}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    deleteData(context, { uuid }) {
      return new Promise((resolve, reject) => {
        $http.delete(`stocks/warehouse/incoming/${uuid || ''}`)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },

    // Stock Retail
    getRetailProducts(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`stocks/retail-product/${uuid || ''}`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    postRetailProducts(context, { payload, uuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`stocks/retail-product/${uuid || ''}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },

    // Stock Actual
    getStockActual(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`stocks/warehouse/actual/${uuid || ''}`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },

    // Stock Supplier Incoming
    getStockSupplier(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`stocks/supplier/incoming/${uuid || ''}`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    postStockSupplier(context, { payload, uuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`stocks/supplier/incoming/${uuid || ''}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },

    // Stock Actual Supplier
    getStockActualSupplier(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`stocks/supplier/actual/${uuid || ''}`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },

    // Stock Transfer
    getTransfer(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`stocks/transfer/${uuid || ''}`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    postTransfer(context, { payload, uuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`stocks/transfer/${uuid || ''}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
