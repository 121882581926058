import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import money from "v-money";
import VueHtmlToPaper from "vue-html-to-paper";

import titleMixin from "@core/mixins/titleMixin";
// import VueEcho from 'vue-echo-laravel'
import { localeChanged } from "vee-validate";
import router from "./router";
import store from "./store";
import App from "./App.vue";
import VueGtag from "vue-gtag";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/tour";

// Format Date
import "@/@core/utils/format-date";
// Vue Filter
import "@/@core/utils/vue-filters";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faHandPaper, faRepeat } from "@fortawesome/free-solid-svg-icons";

import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/assets/css/leaflet.css";

library.add(faHandPaper, faRepeat);

const { hostname, protocol, port } = window.location;

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    `${protocol}//${hostname}${port !== "" ? `:${port}` : ""}/bootstrap.css`,
    // `${protocol}//${hostname}${port !== '' ? `:${port}` : ''}/styles.css`,
    `${protocol}//${hostname}${port !== "" ? `:${port}` : ""}/landscape.css`,
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
};

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});


Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueHtmlToPaper, options);

Vue.use(VueGtag, {
  config: { id: "G-NJEVT8MD6C" },
});

// register directive v-money and component <money>
Vue.use(money, { precision: 4 });

Vue.mixin(titleMixin);

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// window.io = require('socket.io-client')

// Vue.use(VueEcho, {
//   broadcaster: 'socket.io',
//   host: process.env.WEBSOCKET_DOMAIN,
//   options: {}
// })

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

// this.$i18n.locale = 'id'
localeChanged();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
